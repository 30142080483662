<template>
  <EstimatedAnnualEnergyConsumptionRadioField
    :choices="choices"
    v-on="$listeners"
  />
</template>

<script>
import EstimatedAnnualEnergyConsumptionRadioField from 'chimera/all/components/form/fields/estimatedAnnualEnergyConsumption/EstimatedAnnualEnergyConsumptionRadioField'
import {
  solarPanelsPurchaseConsumerBE,
  solarPanelsPurchaseSolarCollectorsConsumerBE,
} from 'chimera/solarPanels/service'
import TSelectableWithTextField from 'chimera/all/components/elements/TSelectableWithTextField'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'EstimatedAnnualEnergyConsumptionFormPart',

  components: {
    EstimatedAnnualEnergyConsumptionRadioField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      corporateChoices: [
        new Selectable('kWh', 'kWh', '', {
          component: TSelectableWithTextField,
          textField: true,
        }),
        new Selectable(
          'Ik kan geen schatting maken',
          'Ik kan geen schatting maken',
          'Ik kan geen schatting maken',
        ),
      ],

      consumerChoices: [
        new Selectable(
          '2.300 kWh (1 persoon)',
          '2.300 kWh (1 persoon)',
          '2.300 kWh (1 persoon)',
        ),
        new Selectable(
          '3.400 kWh (2 personen)',
          '3.400 kWh (2 personen)',
          '3.400 kWh (2 personen)',
        ),
        new Selectable(
          '4.100 kWh (3 personen)',
          '4.100 kWh (3 personen)',
          '4.100 kWh (3 personen)',
        ),
        new Selectable(
          '4.600 kWh (4 personen)',
          '4.600 kWh (4 personen)',
          '4.600 kWh (4 personen)',
        ),
        new Selectable(
          '5.300 kWh (5 personen)',
          '5.300 kWh (5 personen)',
          '5.300 kWh (5 personen)',
        ),
        new Selectable(
          '5.400 kWh (6 personen)',
          '5.400 kWh (6 personen)',
          '5.400 kWh (6 personen)',
        ),
        new Selectable(
          'Meer dan 5.400 kWh',
          'Meer dan 5.400 kWh',
          'Meer dan 5.400 kWh',
        ),
        new Selectable(
          'Ik kan geen schatting maken',
          'Ik kan geen schatting maken',
          'Ik kan geen schatting maken',
        ),
      ],
    }
  },

  computed: {
    /**
     * @returns {Selectable[]}
     */
    choices() {
      const service = this.$store.getters['lead/getData']('service')
      const consumerServices = [
        solarPanelsPurchaseSolarCollectorsConsumerBE.id,
        solarPanelsPurchaseConsumerBE.id,
      ]
      if (consumerServices.includes(service)) {
        return this.consumerChoices
      }

      return this.corporateChoices
    },
  },
}
</script>
